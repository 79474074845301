import React, { useEffect, useState } from "react";
import "../../Style/AI.scss";
import { User } from "../../Functions/LocalSaveData";
import { prelimReport } from "../../Functions/aiFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { PrelSubBox } from "../../Components/Box";
import sws from "../../Assets/ui/ws.png";
import sss from "../../Assets/ui/ss.png";
import nmp from "../../Assets/ui/nmp.png";
import yagt from "../../Assets/ui/yagt.png";
import { dateFormat } from "../../Functions/utils";
import { DlMdl } from "../../Components/Modal";

export default function PrelimReport() {
  const { cId } = useParams();
  const { name } = User();
  const [report, setReport] = useState([]);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => setReport(await prelimReport(cId), setDl(false));
    load();
  }, [cId]);

  let rpD = !report.length > 0 ? [] : report[0];
  const { course_name, exam_date, pre_date } = rpD;

  const navigate = useNavigate();

  return (
    <div className="AiMain">
      <DlMdl v={dl} />
      <div className="plrMain">
        <p className="plrTxt">Preliminary Report</p>
        <div className="usrInfoD">
          <div className="infD">
            <p className="usrInfo">
              User:&nbsp;<span className="infoA">{name}</span>
            </p>
            <p className="usrInfo">
              Exam Name: &nbsp;<span className="infoA">{course_name}</span>
            </p>
          </div>
          <div className="infD">
            <p className="usrInfo">
              Preliminary Date: &nbsp;
              <span className="infoA">
                {pre_date === undefined ? "" : dateFormat(pre_date)}
              </span>
            </p>
            <p className="usrInfo">
              Examination Date: &nbsp;
              <span className="infoA">
                {exam_date === null ? "" : dateFormat(exam_date)}
              </span>
            </p>
          </div>
        </div>
        <div className="subReportD">
          <PrelSubBox
            title={"Selected Weak Subjects"}
            type={"wt"}
            data={
              report.length < 0 ? [] : report.filter((i) => i.status === "weak")
            }
            img={sws}
          />
          <PrelSubBox
            title={"Selected Strong Subjects"}
            type={"st"}
            data={
              report.length < 0
                ? []
                : report.filter((i) => i.status === "strong")
            }
            img={sss}
          />
          <PrelSubBox
            title={"Need More Practice"}
            type={"wt"}
            data={
              report.length < 0
                ? []
                : report.filter(
                    (i) => i.status === "strong" && i.position === "weak"
                  )
            }
            img={nmp}
          />
          <PrelSubBox
            title={"You Are Good In These"}
            type={"st"}
            data={
              report.length < 0
                ? []
                : report.filter(
                    (i) => i.status === "weak" && i.position === "strong"
                  )
            }
            img={yagt}
          />
          <PrelSubBox
            title={"Result Based Weak Subjects"}
            type={"wt"}
            data={
              report.length < 0
                ? []
                : report.filter((i) => i.position === "weak")
            }
            img={sws}
          />
          <PrelSubBox
            title={"Result Based Strong Subjects"}
            type={"st"}
            data={
              report.length < 0
                ? []
                : report.filter((i) => i.position === "strong")
            }
            img={sss}
          />
        </div>
        <p
          onClick={() => {
            navigate("/", { replace: true });
            window.location.reload();
          }}
          className="dnBtn"
        >
          Done
        </p>
      </div>
    </div>
  );
}
