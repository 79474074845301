import { getAllExams } from "./landingFunctions";

export const User = () => {
  let val = localStorage.getItem("user");
  if (val) {
    val = JSON.parse(val);
    val.ai_attempt = val.ai_attempt === "attempt";
    return val;
  } else {
    return [];
  }
};

export const LogType = () => localStorage.getItem("logType");

export const hasCourses = () => localStorage.getItem("hasCourses");

export const loggedIn = async () => {
  let lu = localStorage.getItem("loggedIn");
  if (!lu) {
    return false;
  } else {
    lu = JSON.parse(lu);
    return lu.loggedIn;
  }
};

export const dashData = () => {
  let val = localStorage.getItem("dashData");
  if (!val) {
    return [];
  } else {
    return JSON.parse(val);
  }
};

export const todoList = () => {
  let val = localStorage.getItem("todoList");
  if (val) {
    val = JSON.parse(val);
    const { last_selected_course } = User();
    val = val.filter(
      (i) => String(i.course_id) === String(last_selected_course)
    );

    return val
      .filter((i) => i.test_type === "practice")
      .slice(0, 2)
      .concat(
        val
          .reverse()
          .filter(
            (i) =>
              i.test_type === "read" &&
              (i.status === "weak" || i.position === "weak")
          )
          .slice(0, 2)
      );
  } else {
    return [];
  }
};

export const crsMock = () => {
  let val = localStorage.getItem("crsMocks");
  if (val) {
    val = JSON.parse(val);
    const { last_selected_course } = User();
    val = val.filter(
      (i) => String(i.course_id) === String(last_selected_course)
    )[0];
    return val ? val : [];
  } else {
    return [];
  }
};

export const mockRep = () => {
  let val = localStorage.getItem("MockReport");
  if (!val) {
    return [];
  } else {
    return JSON.parse(val);
  }
};

export const usrSubs = () => {
  const { last_selected_course } = User();
  if (!last_selected_course) {
    return [];
  } else {
    let val = localStorage.getItem("AlSubs");
    if (!val) {
      return [];
    } else {
      val = JSON.parse(val);
      return val
        ? val.filter(
            (i) => String(i.course_id) === String(last_selected_course)
          )
        : [];
    }
  }
};

export const subName = (subId) => {
  let val = localStorage.getItem("AlSubs");
  if (!val) {
    return "";
  } else {
    val = JSON.parse(val);
    val = val.filter((i) => String(i.subject_id) === String(subId))[0];
    return val ? val.subject_name : "";
  }
};

export const subTopics = (subId, tpcId) => {
  let { last_selected_course } = User();
  last_selected_course = last_selected_course ? last_selected_course : 0;
  let val = localStorage.getItem("AlTopics");
  if (!val) {
    return [];
  } else {
    val = JSON.parse(val);
    let val2 = val;
    val = val.filter(
      (i) =>
        String(i.subject_id) === String(subId) &&
        String(i.course_id) === String(last_selected_course)
    );

    if (val.length === 0 && tpcId) {
      let vc = val2.filter((i) => String(i.id) === String(tpcId))[0];
      const { course_id, subject_id } = vc ? vc : [];
      return val2.filter(
        (i) =>
          String(i.subject_id) === String(subject_id) &&
          String(i.course_id) === String(course_id)
      );
    } else {
      return val;
    }
  }
};

export const tpcDetail = (tpcId) => {
  let val = localStorage.getItem("AlTopics");
  if (!val) {
    return [];
  } else {
    val = JSON.parse(val);
    val = val.filter((i) => String(i.id) === String(tpcId)).slice(0, 1)[0];
    return val ? val : [];
  }
};

export const mvpData = () => {
  let val = localStorage.getItem("mvp");
  if (!val) {
    return [];
  } else {
    return JSON.parse(val);
  }
};

export const usrEbook = () => {
  let val = localStorage.getItem("usrEb");
  if (!val) {
    return [];
  } else {
    return JSON.parse(val);
  }
};

export const usrBookmarks = () => {
  let val = localStorage.getItem("bmData");
  if (val) {
    return JSON.parse(val);
  } else {
    return [];
  }
};

export const notify = () => {
  let val = localStorage.getItem("notify");
  if (!val) {
    return [];
  } else {
    return JSON.parse(val);
  }
};

export const exmUpdts = () => {
  let val = localStorage.getItem("exmUpdts");
  if (!val) {
    getAllExams();
    return [];
  } else {
    return JSON.parse(val);
  }
};
