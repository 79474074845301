import React, { useRef, useEffect, useState } from "react";
import "../../Style/home.scss";
import { User, usrSubs } from "../../Functions/LocalSaveData";
import wlcmImg from "../../Assets/ui/pri-illus.png";
import { SubProgWidget, TodoListWidget } from "../../Components/Widgets";
import { SubProgChart } from "../../Components/Charts";
import { usrDash } from "../../Functions/homeFunctions";
import { useNavigate } from "react-router-dom";
import { useForceUpdate, useIsVisible } from "../../Functions/utils";
import { getUsrEbook } from "../../Functions/ebookFunctions";
import { DlMdl } from "../../Components/Modal";
import { CheckUser } from "../../Functions/authFunctions";
import {
  CaNewsCsrl,
  ConVidCrsl,
  ExmUpdtsCrsl,
  UsrEbCrsl,
  VidListCrsl,
} from "../../Components/BoxCarousel";
import { mvpDash } from "../../Functions/mvpFunctions";

export default function Home() {
  const { name, ai_attempt, last_selected_course } = User();
  const navigate = useNavigate();
  const ref = useRef();
  const isVisible = useIsVisible(ref);
  const force = useForceUpdate();
  const [dl, setDl] = useState(true);
  const [data, setData] = useState([]);

  useEffect(
    () => {
      const load = async () => {
        mvpDash().then((v) => setData(v ? v : []));
        await CheckUser();
        await usrDash();
        await getUsrEbook();
        return setDl(false, force());
      };
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (isVisible) {
        force();
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div className="HomeD" ref={ref}>
      <DlMdl v={dl} />
      <div className="DashMain">
        <div className="rowDiv">
          <div className="wlcmTxtD">
            <p className="wlcmTxt">Welcome Back, {name} !</p>
            <label className="keepTxt">
              keep it up and improve your result.
            </label>
            {ai_attempt ? null : (
              <div className="cmpltAiD">
                <p className="cmpTxt">
                  Hi {name.split(" ")[0]}! Please complete your AI question
                  quiz...
                </p>

                <p
                  className="cmpBtn"
                  onClick={() => navigate("/ai/" + last_selected_course)}
                >
                  Complete Now
                </p>
              </div>
            )}
          </div>
          <div className="wlcmImgD">
            <img
              className="wlcmImg"
              alt={"Welcome Back" + name}
              src={wlcmImg}
            />
          </div>
          <ConVidCrsl
            data={data}
            reRender={() => mvpDash().then((v) => setData(v ? v : []))}
          />
          {!ai_attempt ? null : <TodoListWidget />}
          <CaNewsCsrl />
          <VidListCrsl data={data} />
          {!ai_attempt ? null : (
            <>
              <SubProgWidget />
              {usrSubs().length > 0 ? <SubProgChart /> : null}
            </>
          )}
          <UsrEbCrsl type={"cr"} />
          <ExmUpdtsCrsl />
        </div>
      </div>
    </div>
  );
}
