import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";
import { Buffer } from "buffer";

export const getPdf = async (url) => {
  const { token } = User();
  if (url && token) {
    return await axios
      .post(
        Server.GETPDF,
        { url },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((val) => val.data)
      .catch((err) => console.log(err));
  } else {
    return undefined;
  }
};

export const getUsrEbook = async () => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.GETUSREBK,
        { usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => {
        localStorage.setItem("usrEb", JSON.stringify(res.data));
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const cmdEbook = async (ebId, type, cmd, page) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.CMDEBOOK,
        { usrId: id, ebId, type, cmd, page },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async () => await getUsrEbook())
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getEbook = async (ebId) => {
  if (ebId) {
    return await axios
      .post(Server.GETEB, { ebId })
      .then((res) => res.data.ebook)
      .catch((err) => console.log(err));
  }
};

export const getEbThumb = async (id) => {
  const { token } = User();
  return await axios
    .post(
      Server.EBTHUMB,
      { id },
      { headers: { authorization: "Bearer " + token } }
    )
    .then(async (res) => {
      const { thumbnail } = res.data;
      if (thumbnail !== null) {
        let t =
          "data:image/png;base64," +
          Buffer.from(thumbnail?.data).toString("base64");
        let th = await fetch(t);
        return URL.createObjectURL(await th.blob());
      } else {
        return null;
      }
    })
    .catch((err) => console.log(err));
};
