import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";
import { usrLogout } from "./authFunctions";

export const pracExam = async (tpcId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.PRACEXAM,
        { tpcId, usrId: id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => (res.data.status === 1 ? res.data : []))
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};

export const pracSave = async (quesData, cmd, navigate) => {
  const { token } = User();
  const { topic_id } = quesData;
  if (token) {
    return await axios
      .post(
        Server.PRACSAVE,
        { quesData, cmd },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        const { status, test_id } = res.data;
        if (status === 1) {
          return res.data;
        } else {
          return navigate(`/tpc/rpt/${topic_id}/${test_id}`, { replace: true });
        }
      })
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};

export const pracReport = async (topic_id, test_id) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.PRACREPORT,
        { user_id: id, topic_id, test_id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data);
  } else {
    return usrLogout();
  }
};

export const pracAnalysis = async () => {
  const { token, id, last_selected_course } = User();
  if (token) {
    return await axios
      .post(
        Server.PRTSTPROG,
        { usrId: id, crsId: last_selected_course },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data);
  } else {
    return usrLogout();
  }
};

export const quesReport = async (data) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(Server.QUESRPT, data, {
        headers: { authorization: "Bearer " + token },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};
