import React from "react";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PopCrsCrsll,
  Providings,
  RvwCrsll,
} from "../../Components/BoxCarousel";
import {
  faBook,
  faFileAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../Style/land.scss";
import banner from "../../Assets/ui/playstore.gif";
import { offer, providings } from "../../JsonServer/localServer";
import { useState } from "react";
import { LoginModal } from "../../Components/Modal";
import { useParams } from "react-router-dom";

export default function LandingPage() {
  const { lg } = useParams();
  const [lgMdlVsbl, setLgMdlVsbl] = useState(lg ? true : false);
  const [isReg, setIsReg] = useState(false);

  return (
    <div className="homeMain">
      <LoginModal
        visible={lgMdlVsbl}
        setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
        isReg={isReg}
      />
      <div className="landMain">
        <div className="hCon">
          <div className="tsfDiv">
            <p className="tsfTitle"> India's Leading Ed-Tech Platform</p>
            <div className="btnCon">
              <button
                onClick={() => setIsReg(false, setLgMdlVsbl(true))}
                className="login"
              >
                Login
              </button>
              <button
                onClick={() => setIsReg(true, setLgMdlVsbl(true))}
                className="login"
              >
                Register
              </button>
            </div>
          </div>
        </div>
        <div className="caroDiv">
          <Carousel
            autoPlay={false}
            infiniteLoop={true}
            showThumbs={false}
            animationHandler={"fade"}
            showStatus={false}
            showIndicators={false}
            width={"100vw"}
          >
            {offer.map((item, index) => (
              <img key={index} alt="offer" src={item.src} />
            ))}
          </Carousel>
        </div>
        <div className="provDiv">
          <label className="provHead">What we provide</label>
          <div className="gridDiv">
            <Providings data={providings.slice(0, 2)} />
            <Providings data={providings.slice(2, 5)} />
            <Providings data={providings.slice(5, 7)} />
          </div>
          <div className="statDiv">
            <div className="statCon">
              <FontAwesomeIcon icon={faFileAlt} className="statIcon" />
              <label className="statTitle">1500</label>
              <label>Exams</label>
            </div>
            <div className="statCon">
              <FontAwesomeIcon icon={faBook} className="statIcon" />
              <label className="statTitle">5000</label>
              <label>Subjects</label>
            </div>
            <div className="statCon" style={{ borderWidth: 0 }}>
              <FontAwesomeIcon icon={faQuestionCircle} className="statIcon" />
              <label className="statTitle">43442300</label>
              <label>Questions</label>
            </div>
          </div>
        </div>
        <div className="PopCourseDiv">
          <label className="popTitle">Our popular Courses</label>
          <p className="popDesc">
            Here is a collection of our popular courses that you may want. All
            courses are handpicked and <br /> tailored for maximum learning by
            our talented mentors.
          </p>
          <PopCrsCrsll />
          <img alt="Download From PlayStore" src={banner} className="playImg" />
        </div>
        <div className="rvwDiv">
          <label className="rvwTitle">Here's what people say about us</label>
          <RvwCrsll />
        </div>
      </div>
    </div>
  );
}
