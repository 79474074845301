import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";
import { usrLogout } from "./authFunctions";

export const getFreeVidThumb = async (id) => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.FREEVIDTHUMB + id, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (res) => {
        if (res.data) {
          let th = await fetch(res.data);
          return URL.createObjectURL(await th.blob());
        } else return false;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getTpcVidThumb = async (id) => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.TPCVIDTHUMB + id, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (res) => {
        let th = await fetch(res.data);
        return URL.createObjectURL(await th.blob());
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const mvpDash = async () => {
  const { token, last_selected_course, id } = User();
  if (token) {
    return await axios
      .post(
        Server.MVP,
        { crsId: last_selected_course, usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};

export const getVidInfo = async (isFree, vidId) => {
  isFree = String(isFree) === "1";
  vidId = parseFloat(vidId);
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.GETVIDINFO,
        { isFree, vidId, usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};

export const getEduInfo = async (eduId) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.GETEDUINFO,
        { eduId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};

export const getTpcVids = async (tpcId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.GETTPCVIDS,
        { tpcId, usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};

export const vidRead = async (vidData, ref) => {
  ref = ref ? ref : { current: false };
  const { token } = User();
  if (ref.current && token) {
    ref.current = false;
    await axios
      .post(Server.VIDREAD, vidData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(() => true)
      .catch((err) => console.log(err));
    const i = setTimeout(() => {
      ref.current = true;
    }, 10000);
    return () => clearTimeout(i);
  }
};

export const remVidRead = async (vidData) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(Server.REMVIDREAD, vidData, {
        headers: { authorization: "Bearer " + token },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return usrLogout();
  }
};
