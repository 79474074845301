import axios from "axios";
import Server from "../JsonServer/Server";
import jwt_decode from "jwt-decode";
import { User } from "./LocalSaveData";

export const getAlusr = async () =>
  await axios.get(Server.ALUSR).then((r) => r.data);

export const reqOtp = async (mobile, cmd) =>
  await axios
    .post(Server.REQOTP, { mobile, cmd })
    .then((res) => res.data)
    .catch((err) => console.log(err));

export const usrLogin = async (mobile) => {
  return await axios
    .post(Server.ULGOIN, { mobile })
    .then((response) => {
      if (response.data.status === 1) {
        let { user, loggedIn, notify } = response.data;
        let { payment_status, hasCourses } = user;
        user.image = null;
        localStorage.setItem("hasCourses", hasCourses);
        localStorage.setItem("loggedIn", JSON.stringify({ loggedIn }));
        localStorage.setItem("notify", JSON.stringify(notify));
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem(
          "logType",
          payment_status !== 1 ? "pl" : !hasCourses ? "crs" : "li"
        );
        return true;
      } else {
        localStorage.setItem("logType", "lo");
        alert("Something Went Wrong, Please Check");
        return false;
      }
    })
    .catch((err) => console.log(err));
};

export const usrReg = async (usrData) =>
  await axios
    .post(Server.UREG, usrData)
    .then((res) => {
      if (res.data.status === 1) {
        localStorage.setItem("loggedIn", JSON.stringify({ loggedIn: true }));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("logType", "pl");
        alert("Registration Successful");
        return res.data;
      } else {
        alert("Something Went Wrong, Please Check");
        return false;
      }
    })
    .catch((err) => console.log(err));

export const CheckUser = async () => {
  let dcTokn = null;
  const { id, name, token } = User();
  if (token) {
    dcTokn = jwt_decode(token);
    if (Date.now() >= dcTokn.exp * 1000) {
      alert(`Hey ${name}\nToken Expired, Login again!!`);
      return usrLogout();
    } else {
      await axios
        .post(
          Server.UCHECK,
          { id, token },
          { headers: { authorization: "Bearer " + token } }
        )
        .then((res) => {
          const { loggedIn, subs, hasCourses, crsMocks, topics, user, notify } =
            res.data;
          if (loggedIn === false) {
            alert(
              `Hey ${name}\nNew device login detected, kindly login again!!`
            );
            localStorage.setItem("logType", "lo"); // login type = ("lo") means LogOut
            return usrLogout();
          } else {
            user.image = null;
            localStorage.setItem("hasCourses", hasCourses);
            localStorage.setItem("AlSubs", JSON.stringify(subs));
            localStorage.setItem("AlTopics", JSON.stringify(topics));
            localStorage.setItem("crsMocks", JSON.stringify(crsMocks));
            localStorage.setItem("notify", JSON.stringify(notify));
            localStorage.setItem("user", JSON.stringify(user));
            if (localStorage.getItem("logType") !== "pl") {
              localStorage.setItem("logType", hasCourses ? "li" : "crs");
            }
            return true;
          }
        })
        .catch((err) => console.log(err));
    }
  } else {
    localStorage.setItem("logType", "lo");
    return usrLogout();
  }
};

export const upUser = async (usrData, setVisible) => {
  const usr = User();
  const { id, token } = usr;
  usrData.id = id;
  if (token) {
    await axios
      .post(Server.UPUSER, usrData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (response) => {
        console.log(response);
        if (response.data.status === 1) {
          //
          usr.name = usrData.name;
          usr.dob = usrData.dob;
          usr.education = usrData.education;
          usr.image = usrData.image;
          localStorage.setItem("user", JSON.stringify(usr));
          //
          alert("Details Updated Successfully");
          setVisible(false);
        } else {
          alert("Something Went Wrong, Please Check");
        }
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export function usrLogout() {
  localStorage.setItem("loggedIn", JSON.stringify({ loggedIn: false }));
  localStorage.clear();
  window.history.replaceState(null, null, "/");
  window.location.reload();
  return "log out successfully";
}

export const notRead = async (notId) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.NOTI_READ,
        { notId },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then(() => true);
  } else {
    return false;
  }
};

export const getUsrImg = async () => {
  const { id, token } = User();
  if (token) {
    return await axios
      .post(
        Server.GET_USR_IMG,
        { id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return "";
  }
};
