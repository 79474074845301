import React, { useEffect, useState } from "react";
import "../../Style/mvp.scss";
import { mvpDash } from "../../Functions/mvpFunctions";
import {
  ConVidCrsl,
  MvpBannerCrsl,
  SubVidCsrl,
  VidListCrsl,
} from "../../Components/BoxCarousel";
import { DlMdl } from "../../Components/Modal";

export default function MVP() {
  const [data, setData] = useState([]);
  const [dl, setDl] = useState(true);
  useEffect(() => {
    mvpDash().then((v) => {
      v && setData(v);
      setDl(false);
    });
  }, []);
  const { tpcVidSubs } = data;

  return (
    <div className="HomeD mvpMain">
      <DlMdl v={dl} />
      <MvpBannerCrsl />
      <ConVidCrsl
        data={data}
        reRender={() => mvpDash().then((v) => setData(v ? v : []))}
      />
      <VidListCrsl data={data} />
      <SubVidCsrl vidData={tpcVidSubs} />
    </div>
  );
}
