import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";
import { mvpDash } from "./mvpFunctions";
import { CheckUser } from "./authFunctions";

export const usrCrs = async () => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.UCRS,
        { id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data.usrCrs)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const setrCrs = async (crsIds, navigate) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.SETCRS,
        { crsIds, id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        if (res.data.status === 1) {
          CheckUser();
          return navigate("/ai/0", { replace: true });
        } else {
          return false;
        }
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const crsSubs = async (crsId) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.CSUBS,
        { crsId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data.subs)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const usrCrsUpdate = async (crsId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.UCRS_UP,
        { usrId: id, crsId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        const { status, user } = res.data;
        if (status === 1) {
          localStorage.setItem("user", JSON.stringify(user));
          return await mvpDash();
        }
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const remCrs = async (crsId) => {
  const { id, token } = User();
  if (token) {
    return await axios
      .post(
        Server.REMCRS,
        { usrId: id, crsId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};
