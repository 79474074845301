import { Backdrop, Modal } from "@mui/material";
import { MorVidCsrl } from "../../Components/BoxCarousel";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFreeVidThumb,
  getTpcVidThumb,
  getVidInfo,
  vidRead,
} from "../../Functions/mvpFunctions";
import { EduInfoModal } from "../../Components/Modal";
import { User } from "../../Functions/LocalSaveData";

export const VidInfoM = () => {
  const { vidId, isFree } = useParams();
  const [item, setItem] = useState([]);
  const [data, setData] = useState([]);
  const [curTime, setCurTime] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [v, setV] = useState(true);
  const [eduV, setEduV] = useState(false);
  const navigate = useNavigate();
  const readRef = useRef(true);
  const [thumb, setThumb] = useState("");

  useEffect(() => {
    getVidInfo(isFree, vidId).then(async (val) => {
      setData(val);
      const { vidInfo } = val;
      setItem(vidInfo);
      setCurTime(vidInfo.video_time);
      const { freevid, id } = vidInfo;
      if (freevid && id !== undefined) {
        await getFreeVidThumb(id).then((i) => setThumb(i));
      } else {
        await getTpcVidThumb(id).then((i) => setThumb(i));
      }
    });
  }, [isFree, vidId]);

  const { moreVids } = data;

  const {
    video_name,
    video_src,
    views,
    topic_name,
    educator_id,
    educator_name,
    about,
    subject_name,
    subject_id,
    topic_id,
    id,
    duration,
  } = item;

  useEffect(() => {
    let user = User();
    if (id && isPlay) {
      const vidData = {
        vidId: id,
        usrId: user.id,
        freeVid: topic_name === undefined ? 1 : 0,
        video_time: curTime,
        prcnt: Math.round((curTime / duration) * 100),
      };
      vidRead(vidData, readRef);
    }
  }, [curTime, duration, id, isPlay, topic_name]);

  const onReady = useCallback(
    (val) => {
      val.target.currentTime = curTime;
      setIsPlay(true);
    },
    [curTime]
  );

  return (
    <Modal
      open={v}
      onClose={() => {
        setV(false);
        navigate(-1, { replace: true });
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <EduInfoModal
          eduId={educator_id}
          setV={() => setEduV(false)}
          v={eduV}
        />
        <div className="vidPlayerDiv">
          <video
            preload={"auto"}
            onPlay={(val) => onReady(val)}
            onTimeUpdate={(val) => setCurTime(val.target.currentTime)}
            autoPlay
            disablePictureInPicture
            controlsList="nodownload"
            controls
            src={video_src}
            poster={thumb}
            className="vid"
          />
          <div className="vidInfoDiv">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <p className="subName">
                {subject_name === undefined ? "The Study Falcon" : subject_name}
              </p>
              <p className="vidViews">
                {views}
                {"\n"}
                views
              </p>
            </div>
            <div className="vidName">
              {`${topic_name ? topic_name : ""}
                  ${video_name ? video_name : ""}`}
              <p
                className="vidBy"
                style={{ display: educator_name === undefined ? "none" : "" }}
              >
                - By {educator_name}
              </p>
            </div>

            <div
              className="btnDiv"
              style={{ display: about === undefined ? "none" : "" }}
            >
              <p
                onClick={() => navigate(`/tpc/rd/${subject_id}/${topic_id}`)}
                className="btn"
              >
                Read Notes
              </p>
              <p
                onClick={() =>
                  navigate(`/tpc/pt/${subject_id}/${topic_id}/non_prac`)
                }
                className="btn"
              >
                Practice
              </p>
            </div>

            <p
              className="aboutEdu"
              style={{ display: about === undefined ? "none" : "" }}
            >
              About The Trainer
            </p>
            <p
              className="about"
              style={{ display: about === undefined ? "none" : "" }}
            >
              {String(about).slice(0, 200) + "......."}
              <span onClick={() => setEduV(true)} className="contRd">
                Read more{" "}
              </span>
            </p>
            <MorVidCsrl
              vidData={moreVids}
              educator_name={educator_name}
              about={about}
              subject_name={subject_name}
              itm={item}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
