import { useEffect, useState, useRef, forwardRef } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "../Style/Box.scss";
import { getPdfPage } from "../Functions/utils";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  MinimalButton,
  Position,
  ProgressBar,
  ScrollMode,
  SpecialZoomLevel,
  Tooltip,
  ViewMode,
  Viewer,
} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";
import color from "../Style/colorPalette";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
// import { DlMdl } from "./Modal";

export function PDFBook({
  pdf,
  page,
  setPage,
  setTotPages,
  totPages,
  tpcId,
  topics,
}) {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const { innerWidth, innerHeight } = window;
  let isMob = innerWidth <= 500;
  let tp = [];
  tp = topics.filter((i) => String(i.id) === String(tpcId))[0];
  let flipRef = useRef(null);

  const onPage = (e) => {
    setPage(e.data);
  };

  const onLoadSuccess = ({ numPages }) => {
    setTotPages(numPages);
    setPage(getPdfPage(tp.topic_prepration, numPages));
  };

  const getWidth = () => (isMob ? innerWidth - 20 : (innerWidth * 30) / 100);

  const getHeight = () => (isMob ? innerHeight : (innerHeight * 100) / 100);

  const Page = forwardRef(({ pageNumber }, ref) => {
    return (
      <div ref={ref} className="pdfPage">
        <ReactPdfPage
          className="pdf"
          width={getWidth()}
          pageNumber={pageNumber}
        />
      </div>
    );
  });

  return (
    <div className="pdfMain">
      <Document renderMode="canvas" file={pdf} onLoadSuccess={onLoadSuccess}>
        <HTMLFlipBook
          showPageCorners={true}
          autoSize={true}
          width={isMob ? getWidth() * 1 : getWidth() * 2}
          height={isMob ? 500 : 1280}
          showCover={true}
          minWidth={315}
          maxWidth={isMob ? getWidth() : getWidth() * 2}
          minHeight={isMob ? 400 : 600}
          maxHeight={getHeight()}
          maxShadowOpacity={0.5}
          flippingTime={1000}
          size="stretch"
          style={{ margin: "0 auto" }}
          renderOnlyPageLengthChange={true}
          ref={flipRef}
          onFlip={onPage}
          mobileScrollSupport={true}
          startPage={page}
          usePortrait={isMob ? true : false}
        >
          {Array.from(new Array(totPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </HTMLFlipBook>
      </Document>
      {/* <div className="pdfBtnDiv">
        <p
          className="pdfBtn"
          onClick={() => flipRef.current.pageFlip().flipPrev()}
        >
          Previous
        </p>
        <p className="pdfTxt">
          {page + 1} / {totPages}
        </p>
        <p
          className="pdfBtn"
          onClick={() => flipRef.current.pageFlip().flipNext()}
        >
          Next
        </p>
      </div> */}
    </div>
  );
}

export function EbookPDF({ pdf, page, setPage, pdfAdd, pdfRem }) {
  const [totPages, setTotPages] = useState(null);

  const { innerWidth } = window;

  const isMob = innerWidth <= 500;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [pdf]);

  const fullScreenPluginInstance = fullScreenPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="ebPdfVwTlbr">
            <div className="ebpdf1">
              <ShowSearchPopover />
            </div>

            <div className="ebpdf2">
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </div>

            <div className="ebpdf3">
              <GoToPreviousPage />
              <CurrentPageInput /> / <NumberOfPages />
              <GoToNextPage />
            </div>

            <div className="ebpdf4">
              <EnterFullScreen />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    setInitialTab: () => Promise.resolve(0),
    sidebarTabs: (defaultTabs) => (isMob ? [] : [defaultTabs[0]]),
    toolbarPlugin: {
      fullScreenPlugin: {
        renderExitFullScreenButton: (props) => {
          return (
            <div
              style={{
                bottom: "1rem",
                position: "fixed",
                right: "1rem",
                zIndex: 1,
              }}
            >
              <p onClick={props.onClick}>Exit</p>
            </div>
          );
        },
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Vertical
          );
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Page
          );
        },
      },
    },
  });

  const characterMap = {
    isCompressed: true,
    // The url has to end with "/"
    url: "https://unpkg.com/pdfjs-dist@2.6.347/cmaps/",
  };

  const renderError = (error) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message =
          "This E-book is already in use, You can wait or read after some time";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          backgroundColor: color.black,
        }}
      >
        <div
          style={{
            backgroundColor: "#000",
            borderRadius: "0.25rem",
            color: "#000",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const renderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: "none" }}>{props.textLayer.children}</div>
        {props.annotationLayer.children}
      </>
    );
  };

  return (
    <div className="ebPdfMain">
      <div className="rpv-core__viewer">
        <div className="rpv-core__viewer1">
          <GoToPreviousPage>
            {(props) => (
              <Tooltip
                position={Position.BottomCenter}
                target={
                  <MinimalButton onClick={props.onClick}>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleLeft}
                      style={{
                        color: color.lightGreen2,
                        fontSize: 30,
                        boxShadow: `0 0 10px ${color.lightGreen2}`,
                        borderRadius: 100,
                      }}
                    />
                  </MinimalButton>
                }
                content={() => "Previous page"}
                offset={{ left: 0, top: 8 }}
              />
            )}
          </GoToPreviousPage>
        </div>

        <div className="rpv-core__viewer2">
          <GoToNextPage>
            {(props) => (
              <Tooltip
                position={Position.BottomLeft}
                target={
                  <MinimalButton onClick={props.onClick}>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      style={{
                        color: color.lightGreen2,
                        fontSize: 30,
                        boxShadow: `0 0 10px ${color.lightGreen2}`,
                        borderRadius: 100,
                      }}
                    />
                  </MinimalButton>
                }
                content={() => "Next page"}
                offset={{ left: 0, top: 8 }}
              />
            )}
          </GoToNextPage>
        </div>

        <Viewer
          fileUrl={pdf}
          viewMode={isMob ? ViewMode.SinglePage : ViewMode.DualPageWithCover}
          characterMap={characterMap}
          enableSmoothScroll={true}
          scrollMode={ScrollMode.Horizontal}
          defaultScale={SpecialZoomLevel.PageFit}
          renderError={renderError}
          renderPage={renderPage}
          initialPage={page}
          onPageChange={(val) => {
            setPage(val.currentPage);
            if (totPages !== null && val.currentPage + 1 >= totPages) {
              pdfRem();
            } else {
              pdfAdd(val.currentPage);
            }
          }}
          plugins={[
            defaultLayoutPluginInstance,
            fullScreenPluginInstance,
            pageNavigationPluginInstance,
          ]}
          transformGetDocumentParams={(options) =>
            Object.assign({}, options, {
              disableRange: false,
              disableStream: false,
            })
          }
          renderLoader={(percentages) => (
            <div style={{ width: "60%" }}>
              <ProgressBar progress={Math.round(percentages)} />
            </div>
          )}
          onDocumentLoad={(v) => {
            setTotPages(v.doc.numPages);
            // setDl(false);
          }}
          theme={{
            theme: "dark",
          }}
        />
      </div>
    </div>
  );
}

export function CaPdf({ url }) {
  const { innerWidth } = window;

  const isMob = innerWidth <= 500;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const fullScreenPluginInstance = fullScreenPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="ebPdfVwTlbr">
            <div className="ebpdf1">
              <ShowSearchPopover />
            </div>

            <div className="ebpdf2">
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </div>

            <div className="ebpdf3">
              <GoToPreviousPage />
              <CurrentPageInput /> / <NumberOfPages />
              <GoToNextPage />
            </div>

            <div className="ebpdf4">
              <EnterFullScreen />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    setInitialTab: () => Promise.resolve(0),
    sidebarTabs: (defaultTabs) => (isMob ? [] : [defaultTabs[0]]),
    toolbarPlugin: {
      fullScreenPlugin: {
        renderExitFullScreenButton: (props) => {
          return (
            <div
              style={{
                bottom: "1rem",
                position: "fixed",
                right: "1rem",
                zIndex: 1,
              }}
            >
              <p onClick={props.onClick}>Exit</p>
            </div>
          );
        },
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Vertical
          );
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Page
          );
        },
      },
    },
  });

  const characterMap = {
    isCompressed: true,
    // The url has to end with "/"
    url: "https://unpkg.com/pdfjs-dist@2.6.347/cmaps/",
  };

  const renderError = (error) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message =
          "This PDF is already in use, You can wait or read after some time";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          backgroundColor: color.black,
        }}
      >
        <div
          style={{
            backgroundColor: "#000",
            borderRadius: "0.25rem",
            color: "#000",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const renderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: "none" }}>{props.textLayer.children}</div>
        {props.annotationLayer.children}
      </>
    );
  };

  return (
    <div className="ebPdfMain">
      <div className="rpv-core__viewer">
        <div className="rpv-core__viewer1">
          <GoToPreviousPage>
            {(props) => (
              <Tooltip
                position={Position.BottomCenter}
                target={
                  <MinimalButton onClick={props.onClick}>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleLeft}
                      style={{
                        color: color.lightGreen2,
                        fontSize: 30,
                        boxShadow: `0 0 10px ${color.lightGreen2}`,
                        borderRadius: 100,
                      }}
                    />
                  </MinimalButton>
                }
                content={() => "Previous page"}
                offset={{ left: 0, top: 8 }}
              />
            )}
          </GoToPreviousPage>
        </div>

        <div className="rpv-core__viewer2">
          <GoToNextPage>
            {(props) => (
              <Tooltip
                position={Position.BottomLeft}
                target={
                  <MinimalButton onClick={props.onClick}>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      style={{
                        color: color.lightGreen2,
                        fontSize: 30,
                        boxShadow: `0 0 10px ${color.lightGreen2}`,
                        borderRadius: 100,
                      }}
                    />
                  </MinimalButton>
                }
                content={() => "Next page"}
                offset={{ left: 0, top: 8 }}
              />
            )}
          </GoToNextPage>
        </div>

        <Viewer
          fileUrl={url}
          viewMode={isMob ? ViewMode.SinglePage : ViewMode.DualPageWithCover}
          characterMap={characterMap}
          enableSmoothScroll={true}
          scrollMode={ScrollMode.Horizontal}
          defaultScale={SpecialZoomLevel.PageFit}
          renderError={renderError}
          renderPage={renderPage}
          plugins={[
            defaultLayoutPluginInstance,
            fullScreenPluginInstance,
            pageNavigationPluginInstance,
          ]}
          transformGetDocumentParams={(options) =>
            Object.assign({}, options, {
              disableRange: false,
              disableStream: false,
            })
          }
          renderLoader={(percentages) => (
            <div style={{ width: "60%" }}>
              <ProgressBar progress={Math.round(percentages)} />
            </div>
          )}
          theme={{
            theme: "dark",
          }}
          // onDocumentLoad={() => setDl(false)}
        />
      </div>
    </div>
  );
}
