import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllCat, getNewExams } from "../Functions/landingFunctions";
import Server from "../JsonServer/Server";
import Highlighter from "react-highlight-words";
import { FormControl, MenuItem, Select } from "@mui/material";
import { remCrs, usrCrs } from "../Functions/courseFunction";
import color from "../Style/colorPalette";
import { useNavigate } from "react-router-dom";
import { usrLimit } from "../Functions/subsFunctions";
import { getTpcVids } from "../Functions/mvpFunctions";
import { DeProgresBar } from "./Charts";
import { EbookBox, VidBox } from "./Box";
import "../Style/List.scss";
import {
  User,
  crsMock,
  mockRep,
  subTopics,
  usrBookmarks,
} from "../Functions/LocalSaveData";
import {
  CaPdfModal,
  CrsModal,
  DlMdl,
  EbInfo,
  EbPdf,
  LoginModal,
  NewsModal,
  PodPlayer,
  ShareModal,
  SubsPlanModal,
  TpcPracAnlsModal,
  TpcVidModal,
  XmModal,
} from "./Modal";
import {
  calAccuracy,
  compareDt,
  dateFormat,
  isCaBm,
  isTpcBm,
  secToTime,
  timeToSec,
  useForceUpdate,
} from "../Functions/utils";
import {
  faCheck,
  faClose,
  faExclamation,
  faEye,
  faPlayCircle,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  addBookmark,
  allPodcast,
  getUsrBookmarks,
  rmBookmark,
} from "../Functions/CAFunctions";

export const SideCatList = ({ keyword }) => {
  const [cat, setCat] = useState([]);
  const [crs, setCrs] = useState([]);
  const [catItem, setCatItem] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const load = async () => {
      let val = await getAllCat();
      setCat(val?.categories);
      let res = await usrCrs();
      if (res.length > 0) {
        let uCrsIds = new Set(res.map(({ id }) => id));
        setCrs(val.courses.filter(({ id }) => !uCrsIds.has(id)));
      } else {
        setCrs(val.courses);
      }
    };
    load();
  }, []);

  return (
    <div className="crsCoDiv">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      />
      <label className="catTxt">By Category</label>
      <div className="crsList">
        <CrsModal
          data={crs.filter(
            (i) => String(i.course_cat_id) === String(catItem.id)
          )}
          name={catItem.cat_name}
          visible={visible}
          setVisible={() => setVisible(!visible)}
        />
        {cat
          .filter((i) =>
            String(i.cat_name)
              .toLowerCase()
              .includes(String(keyword).toLowerCase())
          )
          .map((item, index) => (
            <div
              className="crsItem"
              key={index}
              onClick={() => setCatItem(item, setVisible(true))}
            >
              <FontAwesomeIcon icon={item.icon} className="crsIcn" />
              <label className="crsTtl">{item.cat_name}</label>
            </div>
          ))}
      </div>
    </div>
  );
};

export const ExamList = ({ search, cat }) => {
  const [xmData, setXmData] = useState([]);
  const [v, setV] = useState(true);
  useEffect(() => {
    const load = async () => setXmData(await getNewExams(), setV(false));
    load();
  }, []);

  const [xmItem, setXmItem] = useState([]);
  const [xmVisible, setXmVisible] = useState(false);

  return (
    <div className="xmList">
      <DlMdl v={v} />
      <XmModal
        item={xmItem}
        visible={xmVisible}
        setVisible={() => setXmVisible(false)}
      />
      <div className="listHead">
        <label className="sno">S.No.</label>
        <label className="lstdt">Category</label>
        <label className="xmNm">Exam / Job Name</label>
        <label className="lstdt">Post Date</label>
        <label className="elgblt mRes">Qualification</label>
        <label className="lstdt mRes">Last Date</label>
        <label className="vw">View</label>
      </div>
      <div className="listData">
        {(cat === "All Updates"
          ? xmData
          : xmData.filter((i) => i.category === cat)
        )
          .filter(
            (i) =>
              String(i.name).toLowerCase().includes(search) ||
              String(i.category).toLowerCase().includes(search) ||
              String(i.qualification).toLowerCase().includes(search)
          )
          .map((item, index) => {
            const {
              name,
              qualification,
              postDate,
              lastDate,
              moreInfo,
              category,
            } = item;
            return (
              <div className="listItem" key={index}>
                <label className="sno">{index + 1}.</label>
                <label className={"lstdt vld"}>{category}</label>
                <label className="xmNm" style={{ textAlign: "unset" }}>
                  {name}
                </label>
                <label className={"lstdt vld"}>{dateFormat(postDate)}</label>
                <label className="elgblt mRes" style={{ textAlign: "unset" }}>
                  {qualification ? qualification : "Not Available"}
                </label>

                <label
                  className={
                    lastDate && compareDt(lastDate) === 1
                      ? "lstdt exp mRes"
                      : "lstdt vld mRes"
                  }
                >
                  {lastDate ? lastDate : "NA"}
                  <br />
                  {lastDate
                    ? compareDt(lastDate) === 1
                      ? "( Expired )"
                      : ""
                    : ""}
                </label>
                <a
                  className="vwEye xmDRes"
                  target="_blank"
                  rel="noreferrer"
                  href={String(moreInfo).replace(">Get", "")}
                >
                  <FontAwesomeIcon className="eyeIcn" icon={faEye} />
                </a>
                <div className="vwEye xmMRes">
                  <FontAwesomeIcon
                    className="eyeIcn"
                    icon={faEye}
                    onClick={() => setXmItem(item, setXmVisible(true))}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const EbookSrch = ({ data }) => {
  const [search, setSearch] = useState("");
  const [ebData, setEbData] = useState([]);
  const [ebSub, setEbSub] = useState(0);
  const [visible, setVisible] = useState(false);
  const [ebItm, setEbItm] = useState([]);
  const [ebRdV, setEbRdV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const navigate = useNavigate();

  const isUSr = User().id;

  const handleSearch = async (val) => {
    let sk = val.target.value;
    setSearch(sk);

    setEbData(
      data.ebooks === undefined || sk.trim().length === 0
        ? []
        : data.ebooks
            .filter((i) =>
              String(i.title).toLowerCase().includes(sk.toLowerCase())
            )
            .slice(0, 20)
    );
  };

  return (
    <div className="ebsrchDiv">
      <div className="ebSearchDiv">
        <div className="ebSearch">
          <LoginModal
            visible={lgMdlVsbl}
            setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
          />
          <EbPdf
            ebId={ebItm.id}
            pdf={ebItm.ebook_pdf}
            visible={ebRdV}
            setVisible={() => setEbRdV(!ebRdV)}
          />
          <EbInfo
            setVisible={() => setVisible(false)}
            visible={visible}
            item={ebItm}
            readFunc={(val) =>
              isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
            }
          />
          <input
            type="text"
            placeholder="Search E-book"
            className="searchInput"
            value={search}
            onChange={handleSearch}
          />
          <FontAwesomeIcon
            style={{
              display: String(search).length === 0 ? "none" : "unset",
            }}
            icon={faClose}
            className="searchIcn"
            onClick={() => setSearch("", setEbData([]))}
          />
        </div>
        <div
          className="ebRsltDiv"
          style={{ display: ebData.length === 0 ? "none" : "unset" }}
        >
          {ebData.map((item, index) => (
            <div
              className="ebItem"
              key={index}
              onClick={() => {
                setEbItm(item);
                setVisible(true);
              }}
            >
              <Highlighter
                highlightClassName="ebKey"
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={item.title}
              />
            </div>
          ))}
        </div>
      </div>
      <FormControl className="ebSubDiv" variant="outlined" size="small">
        <Select
          className="ebslct"
          value={ebSub}
          onChange={(val) => setEbSub(val.target.value)}
        >
          <MenuItem value={0}>Explore Ebook By Subject</MenuItem>
          {data.subjects === undefined
            ? null
            : data.subjects.map((item, index) => (
                <MenuItem
                  onClick={() => navigate(`/eb/sb/${item.id}`)}
                  key={index}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const MockQuesList = ({ ques }) => {
  const navigate = useNavigate();
  ques = ques === undefined ? [] : ques;
  return (
    <div className="MquesLstM">
      <div className="LstHead">
        <p className="qno">Q.no.</p>
        <p className="qSec">Section</p>
        <p className="qStatus"> Status</p>
        <p className="qDif">Difficulty</p>
        <p className="qexpl">Review Question</p>
      </div>
      <div className="lstItmDiv">
        {ques.map(({ section_name, status, difficulty_level, sno }, index) => (
          <div key={index} className="lstItm">
            <p className="qno">{(sno === undefined ? index : sno) + 1}</p>
            <p className="qSec">
              {section_name === null ? "Not available" : section_name}
            </p>
            <p
              className="qStatus"
              style={{
                color:
                  status === "correct"
                    ? color.darkGreen2
                    : status === "wrong"
                    ? color.pink
                    : color.pink2,
              }}
            >
              <b>{status}</b>
            </p>
            <p className="qDif">
              {difficulty_level === 1
                ? "Easy"
                : difficulty_level === 2
                ? "Medium"
                : "Hard"}
            </p>
            <div className="qexpl qexp_alt">
              <p
                onClick={() => {
                  navigate("/mt/emt/" + (sno === undefined ? index : sno));
                }}
                className="qexplBtn"
              >
                View
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const PrevMockList = () => {
  let { prevMock, id, course_id } = crsMock();
  prevMock = prevMock.filter(
    (i) =>
      String(i.course_id) === String(course_id) &&
      String(i.mocktest_id) === String(id)
  );
  const navigate = useNavigate();
  return (
    <div className="prMoLstMain">
      {prevMock.length === 0
        ? null
        : prevMock.map(
            ({ score, created_at, mock_lvl, id, retaken }, index) => (
              <div className="lstItm">
                <div className="lstTxtDiv">
                  <p className="lstTxt">
                    <b>Mock-Test {prevMock.length - index}</b>
                  </p>
                  <p className="lstTxt">
                    Level:{" "}
                    {mock_lvl === 3
                      ? "Hard"
                      : mock_lvl === 2
                      ? "Actual"
                      : "Easy"}
                  </p>
                  <p className="lstTxt">Score: {score}</p>
                  <p className="lstTxt">Retakes Attempted: {retaken}</p>
                  <p className="lstTxt">
                    {retaken > 0 ? "Last Retaken" : "Attempted"} On:{" "}
                    {dateFormat(created_at)}
                  </p>{" "}
                </div>
                <div className="lstBtnDiv">
                  <p
                    onClick={() =>
                      window.confirm(
                        "If you retake this mocktest, Then your current mocktest result will update or overwritten with new results. \n Are you sure!, Do you want to continue ?"
                      )
                        ? navigate(`/mt/me/${mock_lvl}/${id}`)
                        : null
                    }
                    className="lstBtn1"
                  >
                    Retake
                  </p>
                  <p
                    onClick={() => navigate("/mt/mrp/" + id)}
                    className="lstBtn2"
                  >
                    Result
                  </p>
                </div>
              </div>
            )
          )}
    </div>
  );
};

export const OveralMockList = () => {
  const { overalMock } = mockRep();
  return (
    <div className="MquesLstM">
      <div className="LstHead">
        <p className="qno">S.no.</p>
        <p className="qSec">MockTest</p>
        <p className="qStatus"> Score</p>
        <p className="qDif">Accuracy</p>
        <p className="qexpl">Date</p>
      </div>
      <div className="lstItmDiv">
        {overalMock.map(({ created_at, accuracy, score }, index) => (
          <div key={index} className="lstItm">
            <p className="qno">{index + 1}</p>
            <p className="qSec">Mock-Test {overalMock.length - index}</p>
            <p className="qStatus">
              <b>{score}</b>
            </p>
            <p className="qDif">{accuracy}%</p>
            <p className="qexpl">{dateFormat(created_at)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TopicAnList = ({ topics }) => {
  topics = topics ? topics : null;
  return (
    <div className="MquesLstM">
      <div className="LstHead">
        <p className="qno">S.no.</p>
        <p className="qSec">Topic Name</p>
        <p className="qStatus">Total Questions</p>
        <p className="qDif">Attempted</p>
        <p className="qexpl">Accuracy</p>
      </div>
      <div className="lstItmDiv">
        {topics.map(
          ({ topic_name, total_questions, attempt, correct_ans }, index) => (
            <div key={index} className="lstItm">
              <p className="qno">{index + 1}</p>
              <p className="qSec">{topic_name}</p>
              <p className="qStatus">{total_questions}</p>
              <p className="qDif">{attempt}</p>
              <p className="qexpl">{calAccuracy(correct_ans, attempt)}% </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export const TopicList = ({ subId }) => {
  const [subV, setSubV] = useState(false);
  const [vidMV, setVidMV] = useState(false);
  const [tpcName, setTpcName] = useState(null);
  const [vidData, setVidData] = useState([]);
  const [subMsg, setSubMsg] = useState("");
  const navigate = useNavigate();
  const [qCo, setQCo] = useState(0);
  let topics = subTopics(subId);
  let fTpcId = topics.length === 0 ? 0 : topics[0].id;
  let { payment_status } = User();

  useEffect(() => {
    const load = async () => {
      let val = await usrLimit();
      setQCo(val?.quesCount);
    };
    load();
  }, []);

  const isTpcFree = (id) => fTpcId !== id && payment_status !== 1;

  const isQuesFree = () => qCo >= 35 && payment_status !== 1;

  const handleWatch = (val) => {
    if (val.length === 1) {
      const { id } = val[0];
      navigate(`/mvp/${id}/0`);
    } else if (val.length > 1) {
      setVidData(val);
      setVidMV(true);
    }
  };

  return (
    <div className="tpcLstDiv">
      {subV ? (
        <SubsPlanModal
          visible={subV}
          setVisible={() => setSubV(false)}
          msg={subMsg}
        />
      ) : null}
      {vidMV ? (
        <TpcVidModal
          data={vidData}
          visible={vidMV}
          setVisible={() => setVidMV(false)}
          title={tpcName}
        />
      ) : null}
      {topics.map(({ topic_name, id }, index) => (
        <div className="itmDiv" key={index}>
          <div className="sNo">
            <p className="txt">{index + 1}</p>
          </div>
          <p className="topicName">{topic_name}</p>
          <div className="btnDiv">
            <p
              onClick={() =>
                isTpcFree(id)
                  ? setSubMsg(
                      `You can't access this study material .Please upgrade to gain access to complete study material.`,
                      setSubV(true)
                    )
                  : navigate(`/tpc/rd/${subId}/${id}`)
              }
              className={"btn " + (isTpcFree(id) ? "btn-d" : "")}
            >
              Read
            </p>
            <p
              onClick={() =>
                isTpcFree(id)
                  ? setSubMsg(
                      `You can't access this topic videos .Please upgrade to gain access to complete Topic Videos.`,
                      setSubV(true)
                    )
                  : getTpcVids(id).then((val) => {
                      setTpcName(topic_name);
                      handleWatch(val);
                    })
              }
              className={"btn " + (isTpcFree(id) ? "btn-d" : "")}
            >
              Watch
            </p>
            <p
              className={"btn " + (isQuesFree() ? "btn-d" : "")}
              onClick={() =>
                isQuesFree()
                  ? setSubMsg(
                      `You have reached the limit of free plan .Please upgrade to gain access of unlimited questions for practice.`,
                      setSubV(true)
                    )
                  : navigate(`/tpc/pt/${subId}/${id}/non_prac`)
              }
            >
              Practice{" "}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ReadTopicList = ({ subId, tpId, setTpId }) => {
  const [subV, setSubV] = useState(false);
  const usr = User();
  const force = useForceUpdate();
  let navigate = useNavigate();
  const ref = useRef(true);
  let topics = subTopics(subId, tpId);
  let fTpcId = topics.length > 0 ? topics[0].id : 0;
  tpId = tpId === 0 ? topics[0].id : tpId;
  let { payment_status } = User();

  useEffect(() => {
    if (tpId !== fTpcId && ref.current && payment_status !== 1) {
      setSubV(true);
      ref.current = false;
    }
  }, [fTpcId, navigate, payment_status, tpId]);

  const handleBm = async (item) => {
    let uBms = usrBookmarks();
    const { subject_id, id } = item;
    if (isTpcBm(id)) {
      let { tpcBm } = uBms;
      tpcBm = tpcBm ? tpcBm : [];
      let bmId = tpcBm.filter(
        (i) =>
          String(i.topic_id) === String(id) &&
          String(i.subject_id) === String(subject_id)
      )[0];
      bmId = bmId ? bmId.id : null;
      uBms.tpcBm = uBms.tpcBm.filter((i) => String(i.topic_id) !== String(id));
      localStorage.setItem("bmData", JSON.stringify(uBms));
      force();
      rmBookmark(bmId, "tpc");
    } else {
      const bmData = {
        heading: null,
        caId: null,
        category: null,
        type: "tpc",
        subId: subject_id,
        tpcId: id,
      };
      uBms.tpcBm.push({ topic_id: id, user_id: usr.id });
      localStorage.setItem("bmData", JSON.stringify(uBms));
      force();
      addBookmark(bmData);
    }
  };

  const isTpcFree = (id) => {
    return fTpcId !== id && payment_status !== 1;
  };

  return (
    <div className="rdTpcDiv">
      {subV ? (
        <SubsPlanModal
          visible={subV}
          setVisible={() => {
            if (tpId !== fTpcId) {
              navigate(-1);
            }
            setSubV(false);
          }}
          msg={`You can't access this study material .Please upgrade to gain access to complete study material.`}
        />
      ) : null}
      <p className="tpcTxt">Topics</p>
      <div className="rdList">
        {topics.map(({ topic_name, id }, index) => (
          <div
            key={index}
            className={String(tpId) === String(id) ? "actItm" : "rdItm"}
          >
            <p
              onClick={() => (isTpcFree(id) ? setSubV(true) : setTpId(id))}
              className="rdTpc"
            >
              {topic_name}
            </p>
            <FontAwesomeIcon
              style={{ display: isTpcFree(id) ? "none" : "" }}
              onClick={() => handleBm(topics[index])}
              title={isTpcBm(id) ? "Bookmarked" : "Bookmark"}
              icon={(isTpcBm(id) ? "fa-solid" : "fa-regular") + " fa-bookmark"}
              className="naBmIcn"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const AnalysisTable = ({ data, is_overAll }) => {
  const {
    easy_time,
    medium_time,
    hard_time,
    test_time,
    easy_attempt,
    medium_attempt,
    hard_attempt,
    total_attempt,
    easy_score,
    medium_score,
    hard_score,
    total_score,
    easy_wrong,
    medium_wrong,
    hard_wrong,
    total_wrong,
    easy_skipped,
    medium_skipped,
    hard_skipped,
    total_skipped,
  } = data === undefined ? [] : data;

  let es_acc = 0,
    md_acc = 0,
    hd_acc = 0,
    to_acc = 0,
    es_avgT = null,
    md_avgT = null,
    hd_avgT = null,
    to_avgT = null;

  es_avgT = timeToSec(easy_time) / easy_attempt;
  md_avgT = timeToSec(medium_time) / medium_attempt;
  hd_avgT = timeToSec(hard_time) / hard_attempt;
  to_avgT = timeToSec(test_time) / total_attempt;

  es_avgT = isNaN(es_avgT) ? 0 : Math.round(es_avgT);
  md_avgT = isNaN(md_avgT) ? 0 : Math.round(md_avgT);
  hd_avgT = isNaN(hd_avgT) ? 0 : Math.round(hd_avgT);
  to_avgT = isNaN(to_avgT) ? 0 : Math.round(to_avgT);

  es_acc = (easy_score / easy_attempt) * 100;
  md_acc = (medium_score / medium_attempt) * 100;
  hd_acc = (hard_score / hard_attempt) * 100;
  to_acc = (total_score / total_attempt) * 100;

  es_acc = isNaN(es_acc) ? 0 : es_acc.toFixed(2);
  md_acc = isNaN(md_acc) ? 0 : md_acc.toFixed(2);
  hd_acc = isNaN(hd_acc) ? 0 : hd_acc.toFixed(2);
  to_acc = isNaN(to_acc) ? 0 : to_acc.toFixed(2);

  return (
    <div className="curRptM">
      <div className="LstHead">
        <p className="clm"></p>
        <p className="clm">Easy</p>
        <p className="clm">Medium</p>
        <p className="clm">Hard</p>
        <p className="clm">Total</p>
      </div>
      <div className="lstItmDiv">
        <div className="lstItm">
          <p className="clm">Time</p>
          <p className="clm">{easy_time}</p>
          <p className="clm">{medium_time}</p>
          <p className="clm">{hard_time}</p>
          <p className="clm">{test_time}</p>
        </div>
        <div className="lstItm">
          <p className="clm">
            Avg. time / {is_overAll === 1 ? "Test" : "Ques."}
          </p>
          <p className="clm">{secToTime(es_avgT)}</p>
          <p className="clm">{secToTime(md_avgT)}</p>
          <p className="clm">{secToTime(hd_avgT)}</p>
          <p className="clm">{secToTime(to_avgT)}</p>
        </div>
        <div className="lstItm">
          <p className="clm">Attempt</p>
          <p className="clm">{easy_attempt}</p>
          <p className="clm">{medium_attempt}</p>
          <p className="clm">{hard_attempt}</p>
          <p className="clm">{total_attempt}</p>
        </div>
        <div className="lstItm">
          <p className="clm">Score</p>
          <p className="clm">{easy_score}</p>
          <p className="clm">{medium_score}</p>
          <p className="clm">{hard_score}</p>
          <p className="clm">{total_score}</p>
        </div>
        <div className="lstItm">
          <p className="clm">Wrong</p>
          <p className="clm">{easy_wrong}</p>
          <p className="clm">{medium_wrong}</p>
          <p className="clm">{hard_wrong}</p>
          <p className="clm">{total_wrong}</p>
        </div>
        <div className="lstItm">
          <p className="clm">Skipped</p>
          <p className="clm">{easy_skipped}</p>
          <p className="clm">{medium_skipped}</p>
          <p className="clm">{hard_skipped}</p>
          <p className="clm">{total_skipped}</p>
        </div>
        <div className="lstItm">
          <p className="clm">Accuracy</p>
          <p className="clm">{es_acc}%</p>
          <p className="clm">{md_acc}%</p>
          <p className="clm">{hd_acc}%</p>
          <p className="clm">{to_acc}%</p>
        </div>
      </div>
    </div>
  );
};

export const MvpSrchInput = ({ data, keyWord, replace }) => {
  const [search, setSearch] = useState(keyWord === undefined ? "" : keyWord);
  const [ebData, setEbData] = useState([]);
  const navigate = useNavigate();
  const handleSearch = async (val) => {
    let sk = val.target.value;
    setSearch(sk);
    setEbData(
      data === undefined || sk.trim().length === 0
        ? []
        : data.filter((i) =>
            String(i.title).toLowerCase().includes(sk.toLowerCase())
          )
    );
  };

  return (
    <div className="mvpSrchDiv">
      <div className="mvpSrch">
        <input
          type="text"
          placeholder="Search here..."
          className="mvpInput"
          value={search}
          onChange={handleSearch}
        />
        <p
          onClick={() => {
            if (search.length > 0) {
              navigate(`/mvp/src/${search}`, { replace });
              setEbData([]);
            }
          }}
          className="srchBtn"
        >
          Search
        </p>
      </div>
      <div
        className="mvpResDiv"
        style={{ display: ebData.length === 0 ? "none" : "unset" }}
      >
        {ebData.map(({ title, search_cat, title_video, name }, index) => (
          <div
            onClick={() => {
              setEbData([]);
              setSearch(search_cat === 1 ? title_video : name);
              navigate(`/mvp/src/${search_cat === 1 ? title_video : name}`, {
                replace,
              });
            }}
            className="mvpItm"
            key={index}
          >
            <Highlighter
              highlightClassName="mvpKey"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const MockAnalysisList = () => {
  const { prevMock } = crsMock();
  const navigate = useNavigate();
  return (
    <div className="MquesLstM">
      <div className="LstHead">
        <p className="qSec">MockTest</p>
        <p className="qexpl">Date</p>
        <p className="qno">Score</p>
        <p className="qStatus">Accuracy</p>
        <p className="qDif">Result</p>
      </div>
      <div className="lstItmDiv">
        {prevMock &&
          prevMock.map(
            ({ created_at, accuracy, score, test_name, id }, index) => (
              <div key={index} className="lstItm">
                <p className="qSec">{test_name}</p>
                <p className="qexpl">{dateFormat(created_at)}</p>
                <p className="qno">{score}</p>
                <p className="qStatus">{accuracy}%</p>
                <div className="qDif qexp_alt">
                  <p
                    onClick={() => navigate(`/mt/mrp/${id}`)}
                    className="qexplBtn"
                  >
                    View
                  </p>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export const PracAnalysisList = ({ data, tpData }) => {
  tpData = tpData ? tpData : [];
  data = data ? data : [];
  const [visible, setVisible] = useState(false);
  const [subId, setSubId] = useState(0);
  const [subName, setSubName] = useState("");

  console.log(data);
  return (
    <div className="MquesLstM">
      <TpcPracAnlsModal
        visible={visible}
        setVisible={() => setVisible(!visible)}
        data={tpData.filter((i) => String(i.subject_id) === String(subId))}
        subject_name={subName}
      />
      <div className="LstHead">
        <p className="qSec">Subject</p>
        <p className="qexpl">Total Question</p>
        <p className="qno">Correct</p>
        <p className="qStatus">Time Consumed</p>
        <p className="qDif">Topic Wise</p>
      </div>
      <div className="lstItmDiv">
        {data.map(
          (
            {
              subject_name,
              total_question,
              total_correct,
              time_consumed,
              subject_id,
            },
            index
          ) => (
            <div key={index} className="lstItm">
              <p className="qSec">{subject_name}</p>
              <p className="qexpl">{total_question}</p>
              <p className="qno">{total_correct}</p>
              <p className="qStatus">{time_consumed}</p>
              <div className="qDif qexp_alt">
                <p
                  onClick={() => {
                    setSubId(subject_id);
                    setSubName(subject_name);
                    setVisible(true);
                  }}
                  className="qexplBtn"
                >
                  View
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export const TpcAnalysisList = ({ data }) => {
  data = data === undefined ? [] : data;

  return (
    <div className="MquesLstM">
      <div
        className="LstHead"
        style={{
          backgroundColor: color.pink2,
          boxShadow: "0 0 10px " + color.pink2,
        }}
      >
        <p className="qSec">Topic</p>
        <p className="qexpl">Total Questions</p>
        <p className="qno">Correct</p>
        <p className="qStatus">Accuracy</p>
        <p className="qDif">Time Consumed</p>
      </div>
      <div className="lstItmDiv">
        {data.map(
          (
            {
              topic_name,
              total_question,
              total_correct,
              accuracy,
              time_consumed,
            },
            index
          ) => (
            <div key={index} className="lstItm">
              <p className="qSec">{topic_name}</p>
              <p className="qexpl">{total_question}</p>
              <p className="qno">{total_correct}</p>
              <p className="qDif">{accuracy}%</p>
              <p className="qStatus">{time_consumed}</p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export const DeAnalysisList = ({ data }) => {
  data = data ? data : [];
  let readTask = data.filter((i) => i.test_type === "read");
  let pracTask = data.filter((i) => i.test_type === "practice");

  const getRead = (tpcId) => {
    let t = readTask.filter((i) => i.id === tpcId);
    if (t.length === 0) {
      t = "Not Completed";
    } else {
      t = t[0].complete === null ? "Not Completed" : "Completed";
    }
    return t;
  };

  return (
    <div className="MquesLstM">
      <div className="LstHead">
        <p className="qSec">Topic</p>
        <p className="qno">Status</p>
        <p className="qexpl">Total Question</p>
        <p className="qStatus">Content</p>
        <p className="qDif">Progress</p>
      </div>
      <div className="lstItmDiv">
        {pracTask.map(
          ({ topic_name, prac, mock, topic_prepration, id }, index) => {
            topic_prepration = isNaN(parseFloat(topic_prepration))
              ? 0
              : parseFloat(topic_prepration);
            return (
              <div key={index} className="lstItm">
                <p className="qSec">{topic_name}</p>
                <p className="qno">
                  <FontAwesomeIcon
                    title={topic_prepration < 50 ? "Weak" : "Strong"}
                    className={topic_prepration < 50 ? "wtC" : "stC"}
                    icon={topic_prepration < 50 ? faExclamation : faCheck}
                  />
                </p>
                <p className="qexpl">{prac + mock}</p>
                <p className="qDif">{getRead(id)}</p>
                <p className="qStatus">
                  <DeProgresBar progress={topic_prepration} type={"st"} />
                </p>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export const EbSdList = ({
  data,
  gnrId,
  setGnrId,
  setGnrNm,
  title,
  navigate,
  type,
}) => {
  data = data ? data : [];
  return (
    <div className="naCatDiv mCatRes">
      <p className="tpcTxt">{title}</p>
      <div className="caLst" style={{ maxHeight: "82vh" }}>
        {data.map(({ name, id }, index) => (
          <div
            onClick={() => {
              navigate(id);
              // eslint-disable-next-line no-unused-expressions
              setGnrNm ? setGnrNm(name) : null;
              setGnrId(id);
            }}
            className={String(gnrId) === String(id) ? "actNaItm" : "naItm"}
            style={{ textAlign: "center" }}
            key={index}
          >
            <p className="naCat"> {name}</p>
          </div>
        ))}
        {/* <p
          style={{ display: type === "ca" ? "unset" : "none" }}
          onClick={() => nav(`/ca/q`)}
          className="rdItm tqBtm"
        >
          Take Quiz
        </p> */}
      </div>
    </div>
  );
};

export const EbGridList = ({ data }) => {
  const [search, setSearch] = useState("");
  const [v, setV] = useState(false);
  const [ebItm, setEbItm] = useState([]);
  const [ebRdV, setEbRdV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const isUSr = User().id;
  const [lstC, setLstC] = useState(100);
  const lstDivRef = useRef(null);
  const handleChange = (val) => {
    setSearch(val.target.value);
  };

  useEffect(() => {
    setLstC(100);
    lstDivRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [data.length]);

  data =
    search.trim().length === 0
      ? data
      : data.filter((i) =>
          String(i.title)
            .toLowerCase()
            .includes(String(search).trim().toLowerCase())
        );
  let d = data;

  data = data.slice(0, lstC);

  return (
    <div className="ebGrdMain">
      <div className="Search">
        <LoginModal
          visible={lgMdlVsbl}
          setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
        />
        <EbInfo
          visible={v}
          setVisible={() => setV(!v)}
          item={ebItm}
          readFunc={(val) =>
            isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
          }
        />
        <EbPdf
          ebId={ebItm.id}
          pdf={ebItm.ebook_pdf}
          visible={ebRdV}
          setVisible={() => setEbRdV(!ebRdV)}
        />
        <input
          type="text"
          placeholder="Search"
          className="searchInput"
          value={search}
          onChange={handleChange}
        />
        {search.length === 0 ? null : (
          <FontAwesomeIcon
            icon={faClose}
            className="searchIcn"
            onClick={() => setSearch("")}
          />
        )}
      </div>
      <div className="ebList" ref={lstDivRef}>
        {data.map((item, index) => (
          <EbookBox
            key={index}
            item={item}
            width={180}
            setEbItm={(val) => setEbItm(val, setV(true))}
            readFunc={(val) =>
              isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
            }
          />
        ))}
        <div className="ldMrDiv">
          {lstC >= d.length ? null : (
            <p onClick={() => setLstC(lstC + 100)} className="ldMrBtn">
              Load More
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export const PodList = () => {
  const [data, setData] = useState([]);
  const [v, setV] = useState(false);
  const [shV, setShV] = useState(false);
  const [ind, setInd] = useState("");
  const [pId, setPid] = useState(0);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => setData(await allPodcast(), setDl(false));
    load();
  }, []);

  return (
    <div className="pdLstMain">
      <DlMdl v={dl} />
      <PodPlayer v={v} setV={() => setV(false)} item={data[ind]} />
      <ShareModal
        setV={() => setShV(false)}
        title={"Share this Podcast via"}
        v={shV}
        shareUrl={Server.WEB_URL + "pd/" + pId}
      />
      {data.map(({ title, podcast_date, id }, index) => (
        <div className="pdItm" key={index}>
          <FontAwesomeIcon
            icon={faPlayCircle}
            className="pdIcn"
            onClick={() => {
              setInd(index);
              setV(true);
            }}
          />
          <p className="pdTtl">{title}</p>
          <div className="pdShDiv">
            <p className="pdDt">{dateFormat(podcast_date)}</p>
            <FontAwesomeIcon
              onClick={() => {
                setPid(id);
                setShV(true);
              }}
              icon={faShareAlt}
              className="pdSh"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const CaList = ({ data, prefUrl, type, caType }) => {
  data = data ? data : [];
  const [pdf, setPdf] = useState("");
  const [v, setV] = useState(false);
  const [subV, setSubV] = useState(false);
  const force = useForceUpdate();

  const handleBm = (item, isBm) => {
    const { heading, id } = item;
    if (isBm) {
      let { caBm } = usrBookmarks();
      caBm = caBm ? caBm : [];
      let bmId = caBm.filter(
        (i) =>
          String(i.current_affair_id) === String(id) && i.category === caType
      )[0];
      bmId = bmId ? bmId.id : null;

      rmBookmark(bmId, "ca").then(() => force());
    } else {
      const bmData = {
        heading,
        caId: id,
        category: caType,
        type: "ca",
        subId: null,
        tpcId: null,
      };
      addBookmark(bmData).then(() => force());
    }
  };

  const isFreeMag = (item) => {
    const { payment_status } = User();
    const { category_id, id } = item;

    if (category_id === 13 && payment_status !== 1) {
      let secId = data[1].id;
      console.log(id, secId);
      return id !== secId;
    }
  };

  return (
    <div className={"caLstDiv " + (type === "ma" ? "maLst" : "")}>
      <SubsPlanModal
        visible={subV}
        setVisible={() => setSubV(false)}
        msg={`Kindly become a paid subscriber to gain access to all the issues of Falconpedia.`}
      />
      <CaPdfModal
        pdf={pdf}
        setVisible={() => setV(false)}
        visible={v}
        prefUrl={prefUrl}
      />
      {data.map(({ heading, pdf, id }, index) => (
        <div className="caItm" key={index}>
          <div className="caIndDiv">
            <p className="caInd">{index + 1}</p>
          </div>
          <p className="caHeading">{heading}</p>
          <FontAwesomeIcon
            onClick={() => handleBm(data[index], isCaBm(id, caType))}
            title={isCaBm(id, caType) ? "Bookmarked" : "Bookmark"}
            icon={
              (isCaBm(id, caType) ? "fa-solid" : "fa-regular") + " fa-bookmark"
            }
            className="caBmIcn"
          />
          <p
            className="caRdBtn"
            onClick={() =>
              isFreeMag(data[index]) ? setSubV(true) : setPdf(pdf, setV(true))
            }
          >
            Read
          </p>
        </div>
      ))}
    </div>
  );
};

export const MaCatLst = ({ data, setCatId, catId }) => {
  return (
    <div className="maCatDiv">
      {data.map(({ category, id }, index) => (
        <p
          className={"maCat " + (id === catId ? "actMa" : "")}
          onClick={() => setCatId(id)}
          key={index}
        >
          {category}
        </p>
      ))}
    </div>
  );
};

export const RemCrsLst = () => {
  const [data, setData] = useState([]);
  const r = useRef(true);
  useEffect(() => {
    if (r.current) {
      r.current = false;
      usrCrs().then((i) => setData(i ? i : []));
    }
  }, []);

  return (
    <div className="rmLstMain">
      {data.map(({ course_name, progress, id }, index) => (
        <div key={index} className="rmLstItm">
          <div className="rmIndDiv">
            <p className="rmInd">{index + 1}</p>
          </div>
          <div className="rmInfoDiv">
            <p className="rmCrs">{course_name}</p>
            <p className="rmProg">
              Current Progress : {isNaN(parseFloat(progress)) ? "0" : progress}%
            </p>
          </div>
          <p
            onClick={() => {
              if (
                window.confirm(
                  `Are you Sure, you want to remove "${course_name}" from your course list ?`
                )
              ) {
                remCrs(id).then(() =>
                  usrCrs().then((i) => setData(i ? i : []))
                );
              }
            }}
            className="rmBtn"
          >
            Remove
          </p>
        </div>
      ))}
    </div>
  );
};

export const TpcBmLst = () => {
  const navigate = useNavigate();
  let { tpcBm } = usrBookmarks();
  tpcBm = tpcBm === undefined ? [] : tpcBm;

  return (
    <div className="rmLstMain">
      {tpcBm.map(
        ({ topic_name, subject_name, subject_id, topic_id, id }, index) => (
          <div className="rmLstItm" key={index}>
            <div className="rmIndDiv">
              <p className="rmInd">{index + 1}</p>
            </div>
            <div className="rmInfoDiv bmInfo">
              <p className="rmCrs">{topic_name}</p>
              <p className="rmProg"> {subject_name}</p>
            </div>
            <p
              onClick={() => navigate(`/tpc/rd/${subject_id}/${topic_id}`)}
              className="rdBtn"
            >
              Read
            </p>
            <p
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure! you want to remove "${topic_name}" from your bookmarks?`
                  )
                ) {
                  rmBookmark(id, "tpc").then(() => getUsrBookmarks());
                }
              }}
              className="rmBtn"
            >
              Remove
            </p>
          </div>
        )
      )}
    </div>
  );
};

export const CaBmLst = () => {
  const [v, setV] = useState(false);
  const [i, setI] = useState([]);
  const [naV, setNaV] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [pUrl, setPUrl] = useState("");
  const force = useForceUpdate();
  let uBms = usrBookmarks();

  let { caBm } = uBms;
  caBm = caBm ? caBm : [];

  const handleRead = (item) => {
    const { category, pdf } = item;
    let p =
      category === "pib"
        ? Server.PIB_SRC
        : category === "dna"
        ? Server.DNA_SRC
        : category === "mag"
        ? Server.MAG_SRC
        : "";

    if (category === "na") {
      setI(item);
      setNaV(true);
    } else {
      setPUrl(p);
      setPdf(pdf);
      setV(true);
    }
  };

  return (
    <div className="rmLstMain">
      <CaPdfModal
        pdf={pdf}
        setVisible={() => setV(false)}
        visible={v}
        prefUrl={pUrl}
      />
      <NewsModal item={i} setV={() => setNaV(false)} v={naV} />
      {caBm.map(({ id, heading, category }, index) => (
        <div className="rmLstItm" key={index}>
          <div className="rmIndDiv">
            <p className="rmInd">{index + 1}</p>
          </div>
          <div className="rmInfoDiv bmInfo">
            <p className="rmCrs">{heading}</p>
            <p className="rmProg">
              {" "}
              {category === "na"
                ? "News Analysis"
                : category === "pib"
                ? "PIB Report"
                : category === "dna"
                ? "Detailed News Analysis"
                : "Magazine Analysis"}
            </p>
          </div>
          <p onClick={() => handleRead(caBm[index])} className="rdBtn">
            Read
          </p>
          <p
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure! you want to remove this current affair from your bookmarks?`
                )
              ) {
                rmBookmark(id, "ca").then(() => {
                  uBms.caBm = uBms.caBm.filter(
                    (i) => String(i.current_affair_id) !== String(id)
                  );
                  localStorage.setItem("bmData", JSON.stringify(uBms));
                  force();
                });
              }
            }}
            className="rmBtn"
          >
            Remove
          </p>
        </div>
      ))}
    </div>
  );
};

export const VidLst = ({ itm, videos, setV }) => {
  videos = videos === undefined ? [] : videos;
  videos = videos.filter((i) => i.id !== itm.id);
  return (
    <div
      style={{
        display: "flex",
        width: "90%",
        paddingInline: "5%",
        maxHeight: "74vh",
        flexWrap: "wrap",
        overflow: "hidden scroll",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {videos.map((item, index) => (
        <VidBox key={index} item={item} width={"42%"} />
      ))}
    </div>
  );
};
